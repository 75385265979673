<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="entityMarketingEdit"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <Modal ref="modal" title="Marketinginformatie bewerken">
      <FormulateInput
        type="checkbox"
        name="published"
        label="Gepubliceerd"
        :disabled="publishedIsDisabled"
      />
      <FormulateInput
        v-show="property"
        type="checkbox"
        name="is_sneak_peek"
        label="Sneak peek"
        :disabled="sneakPeekIsDisabled"
      >
        <template #label>
          <label for="is_sneak_peek" class="formulate-label">
            Sneak peek
            <Tooltip class="tw-inline-block">
              <i class="fas fa-info-circle" />
              <template #popper>
                <div class="tw-text-center">
                  Met een sneak peek trekken we al potentiële kopers aan voordat het dossier volledig gepubliceerd is. Deze teaser komt op de website en op Facebook- en Instagram-stories met een wazige foto.
                </div>
              </template>
            </Tooltip>
          </label>
        </template>
      </FormulateInput>
      <FormulateInput
        v-show="property && values.is_sneak_peek"
        type="file"
        name="sneak_peek_picture"
        label="Sneak peek foto"
        :uploader="uploadPicture"
        outer-class="formulate-input tw-my-0"
        wrapper-class="formulate-input-wrapper"
        :upload-area-mask-class="['tw-h-10 tw-mt-1']"
        :file-class="['tw-h-10 !tw-mt-1']"
        element-class="formulate-input-element formulate-input-element--file"
      >
        <template #file="context">
          <BaseFileUploadDisplay v-bind="context" />
        </template>
      </FormulateInput>
      <FormulateInput
        type="checkbox"
        name="is_visible_customer_portal"
        label="Zichtbaar op klantenportaal"
      />
      <FormulateInput
        type="url"
        name="video_url"
        label="YouTube-URL"
        placeholder="YouTube-URL"
      />
      <FormulateInput
        type="number"
        name="website_hits"
        label="Websitehits"
        placeholder="Websitehits"
      />
      <FormulateInput
        type="number"
        name="hmodh_sent"
        label="Verstuurde HMODH's"
        placeholder="Verstuurde HMODH's"
      />
      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            data-dismiss="modal"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { successModal, errorModal } from '@/modalMessages'
import {
  getPropertyPictures,
  updatePropertyMarketing,
  uploadPropertyPicture
} from '@/services/properties'
import { updateProjectMarketing, getProjectPictures } from '@/services/projects'
import { Tooltip } from 'floating-vue'

export default {
  name: 'EntityMarketingEditModal',
  components: {
    Tooltip,
    Modal
  },
  props: {
    property: {
      type: Object,
      required: false
    },
    propId: {
      type: Number,
      required: false
    },
    projectId: {
      type: Number,
      required: false
    },
    marketing: {
      type: Object
    }
  },
  data () {
    return {
      values: {},
      pictureIdForSneakPeek: null
    }
  },
  computed: {
    publishedIsDisabled () {
      // Property shouldn't be able to be published if it is currently unpublished
      // and the property's status is:
      // - prospect (1)
      // - removed from portfolio (15)
      // - dossier preparation (16)
      // Always allow depublishing
      return !this.marketing?.published && [1, 15, 16].includes(this.property?.status)
    },
    sneakPeekIsDisabled () {
      // Property shouldn't be able to be a sneak peek if it is currently not a sneak peek
      // and the property's status is not dossier preparation (16):
      // Always allow removing sneak peek
      if (process.env.VUE_APP_ENVIRONMENT === 'prod') return true // Disable sneak peek in production for now
      if (!this.property) {
        return true
      }
      const isPublished = this.marketing.published
      const isNotSneakPeek = !this.marketing?.is_sneak_peek
      const isNotDossierPreparation = this.property?.status !== 16
      return isPublished || (isNotSneakPeek && isNotDossierPreparation)
    }
  },
  methods: {
    show () {
      const { website_hits, hmodh_sent } = this.marketing.statistics
      const { published, is_sneak_peek, is_visible_customer_portal, video_url } = this.marketing
      this.values = { published, is_sneak_peek, is_visible_customer_portal, video_url, website_hits, hmodh_sent }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async submit (data) {
      let pictureCount = 0
      if (this.propId) {
        const picturesPayload = { propertyId: this.propId }
        pictureCount = (await getPropertyPictures(picturesPayload)).data.count
      } else {
        pictureCount = (await getProjectPictures(this.projectId)).data.count
      }
      if (this.values?.is_sneak_peek && pictureCount === 0 && !this.pictureIdForSneakPeek) {
        errorModal(
          'Om gebruik te kunnen maken van sneak peek moet er minstens één foto zijn voor dit pand of moet je een sneak peek foto uploaden.', 'Sneak peek'
        )
        return
      }
      try {
        const statistics = {
          hmodh_sent: data.hmodh_sent,
          website_hits: data.website_hits
        }
        data.statistics = statistics
        data.sneak_peek_picture_id = this.pictureIdForSneakPeek
        const response = this.propId
          ? await updatePropertyMarketing(this.propId, data)
          : await updateProjectMarketing(this.projectId, data)

        this.hide()
        this.$emit('reloadMarketing')
        successModal('Marketinginformatie succesvol bewerkt.')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityMarketingEdit')
      }
    },
    parsePictureObj (picture) {
      if (!picture) return false
      // Formulate input requires filename and url
      const { id, url } = picture
      return [{ id, url }]
    },
    async uploadPicture (picture, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', picture)
        progress(50)
        const response = await uploadPropertyPicture(this.propId, formData)
        this.pictureIdForSneakPeek = response.data.id
        progress(100)
        return this.parsePictureObj(response.data)
      } catch (error) {
        this.$formulate.handle(error, 'entityMarketingEdit')
      }
    }
  }
}
</script>
